import icon_complete from "../../assets/img/icon_complete.png";
import { Link, useParams } from "react-router-dom";
export const CompleteSignUp = () => {
	const params = useParams();
	return (
		<div id="completeDialog">
			<img src={icon_complete} alt="" />
			<p>登録が完了しました。</p>
			<Link to={`/${params.store}/login`} className="normal_btn">
				ログイン画面へ
			</Link>
		</div>
	);
};
