import React, { useEffect, useCallback } from "react";
import { CustomApi } from "../hooks/useAPi";
import { useSearchParams } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

export const RedirectPayment = () => {
	const [searchParams] = useSearchParams();
	const params = useParams();
	const [cookies] = useCookies(["customer_id", "auth_id"]);

	const update = async () => {
		const data = await CustomApi<{ id: number }>(
			"paypay/update",
			{
				id: Number(searchParams.get("id")),
			},
			cookies.auth_id,
			cookies.customer_id
		);
	};
	useEffect(() => {
		update();
	}, []);
	return (
		<div id="payComplete" className="cont_wrapper">
			<div>
				<h3>決済が完了しました。</h3>
				<Link className="normal_btn" to={`/${params.store}/`}>
					商品一覧へ
				</Link>
			</div>
		</div>
	);
};
