import icon_complete from "../../assets/img/icon_complete.png";
type props = {
	close: () => void;
};
export const CompleteDialog = ({ close }: props) => {
	return (
		<div id="completeDialog">
			<img src={icon_complete} alt="" />
			<p>完了しました。</p>
			<button onClick={() => close()} className="normal_btn">
				完了
			</button>
		</div>
	);
};
