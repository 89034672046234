import { item } from "../../types/index";

type props = {
	item: item;
	clickOrder: (i: item) => void;
};

export const ProductItem = ({ item, clickOrder }: props) => {
	return (
		<div className="product_item">
			<div className="product_item_img">
				<img src={item.image_url} className="" alt="" />
			</div>
			<div className="product_item_info">
				<p className="product_item_title">{item.product_name}</p>
				<p className="product_item_detail">{item.description}</p>
				<p className="product_stock">
					在庫:
					<span className={item.stock === 0 ? "noneStock" : ""}>
						{item.infinite_inventory_flag === 0
							? item.stock!.toLocaleString()
							: "無制限"}
					</span>
				</p>
				<p className="product_price">
					<span>¥{item.amount.toLocaleString()}</span>
					(税込)
				</p>
				<div className="product_item_buttons">
					<button
						className="normal_btn"
						type="button"
						disabled={item.stock === 0}
						onClick={() => (item.stock === 0 ? "" : clickOrder(item))}
					>
						注文する
					</button>
				</div>
			</div>
		</div>
	);
};
