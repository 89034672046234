import React, { useState, useCallback, useEffect, memo } from "react";
import { item } from "../../types/index";
// import paypay from "../../assets/img/logo_paypay.svg";
import { maxLengthError } from "../../const/validationMessage";
import { useForm } from "react-hook-form";

type props = {
	orderItem: item;
	success: () => void;
	close: () => void;
	orderMessage?: (i: string) => void;
	orderItemQuantity?: (i: number) => void;
};
export const OrderConfirm = memo((props: props) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
	} = useForm<{ message: string; quantity: number }>({
		criteriaMode: "all",
		mode: "onBlur",
	});
	const onsubmit = (values: { message: string; quantity: number }) => {
		props.orderMessage!(values.message);
		props.orderItemQuantity!(values.quantity);
		props.success();
	};
	const [amount, setAmount] = useState<number>(0);
	const [amountError, setAmountError] = useState<boolean>(true);
	const [checkAmountError, setCheckAmountError] = useState<boolean>(true);

	const changeAmount = useCallback(() => {
		if (
			isNaN(Number(getValues("quantity"))) ||
			Number(getValues("quantity")) <= 0
		) {
			return setCheckAmountError(true);
		} else {
			setCheckAmountError(false);
		}
		if (
			props.orderItem.stock &&
			props.orderItem.stock < Number(getValues("quantity")) &&
			props.orderItem.infinite_inventory_flag === 0
		) {
			setAmountError(true);
		} else {
			setAmountError(false);
			setAmount(props.orderItem.amount * getValues("quantity"));
		}
	}, []);

	useEffect(() => {
		changeAmount();
	}, []);
	return (
		<div id="orderDialog" className="confirm_dialog">
			<div className="dialog_view">
				<div className="item_img">
					<img src={props.orderItem.image_url} className="" alt="" />
				</div>
				<p className="item_title">{props.orderItem.product_name}</p>
				<p className="quantity_text">数量</p>
				<input
					defaultValue="1"
					{...register("quantity", {
						maxLength: maxLengthError(140),
					})}
					className="quantity_input"
					placeholder="数量"
					type="text"
					// onChange={(e) => checkAmount(e.target.value)}
					onBlur={changeAmount}
				/>

				{errors.quantity?.type === "maxLength" && (
					<p className="alert">{errors.quantity?.types?.maxLength}</p>
				)}
				{amountError && (
					<p className="alert">
						{props.orderItem.stock}個以下の数量を選択してください
					</p>
				)}
				<div className="total_wrap">
					<p className="total_price">
						合計 <span>¥{amount.toLocaleString()}</span>
						<span>(税込)</span>
					</p>
				</div>

				<p className="message">メッセージ</p>
				<input
					{...register("message", {
						maxLength: maxLengthError(140),
					})}
					className="message_text"
					placeholder="メッセージを入力して下さい(140文字以内)"
					type="text"
				/>

				{errors.message?.type === "maxLength" && (
					<p className="alert">{errors.message?.types?.maxLength}</p>
				)}
				<div className="gray_line"></div>

				<div className="check_area">
					{/* <div className="paypay">
						<img src={paypay} className="" alt="" />
					</div> */}
				</div>

				<div className="button_flex">
					<button onClick={() => props.close()} className="outline_pink_btn">
						キャンセル
					</button>
					<button
						onClick={handleSubmit(onsubmit)}
						type="submit"
						className="normal_btn"
						disabled={amountError || checkAmountError}
					>
						決済する
					</button>
				</div>
			</div>
		</div>
	);
});
