import icon_fail from "../../assets/img/icon_fail.png";
type props = {
	close: () => void;
};
export const FailureDialog = (props: props) => {
	return (
		<div id="failureDialog">
			<h3>注文に失敗しました</h3>
			<img src={icon_fail} alt="" />
			<p>再度初めからやり直してください</p>
			<button onClick={() => props.close()} className="normal_btn">
				商品一覧へ戻る
			</button>
		</div>
	);
};
