import { useNavigate, useParams } from "react-router-dom";

export const PassResetCompleted = () => {
	const navigate = useNavigate();
	const params = useParams();

	return (
		<div id="passResetCompleted" className="cont_wrapper">
			<p>パスワードの設定が完了しました。</p>
			<div className="button_center">
				<button
					type="submit"
					className="normal_btn"
					onClick={() => navigate(`/${params.store}/login`)}
				>
					ログイン画面へ
				</button>
			</div>
		</div>
	);
};
