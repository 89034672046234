import { useState, useEffect, useCallback } from "react";
import { item, typeCategory } from "../types/index";
import { useNavigate } from "react-router-dom";

import { Pagination } from "../components/common/pagination";
import { CustomApi } from "../hooks/useAPi";
import { BaseDialog } from "../components/dialog/baseDialog";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { ProductItem } from "../feature/productList/ProductItem";
export const ProductList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [cookies] = useCookies(["store_id", "customer_id", "auth_id"]);
	const [category, setCategory] = useState<typeCategory[]>([]);

	// 何番目のページか保有する
	const [currentPage, setCurrentPage] = useState<number>(1);
	// レスポンスデータを保有する
	const [responseData, setResponseData] = useState<item[]>([]);

	// 表示用のステート(ページネーション毎に表示数を保有する)
	const [showProductList, setShowProductList] = useState<item[]>([]);

	// フィルター結果を保有するステート(フィルター結果を保持して、showProductListステートに表示ページ毎のデータを登録する)
	const [productList, setProductList] = useState<item[]>([]);

	// ページネーションのために、何件データがあるかを保有する
	const [productLength, setProductLength] = useState<number>(0);
	const [dialog, setDialog] = useState<boolean>(false);
	const [propsDialog, setPropsDialog] = useState<item>();

	const [dialogType, setDialogType] = useState<
		"orderConfirm" | "complete" | "failure" | "stripe"
	>("orderConfirm");

	const [orderMessage, setOrderMessage] = useState<string>("");
	const [orderItemQuantity, setOrderItemQuantity] = useState<number>(1);
	// 1ページあたりの表示数
	const maxProductView = 10;
	// const data = usePagination(10, productList);
	const getProductLists = useCallback(async () => {
		dispatch({ type: "loading", payload: true });
		const categoryData = await CustomApi<{ store_id: number }>(
			"category/index",
			{
				store_id: cookies.store_id,
			},
			cookies.auth_id,
			cookies.customer_id
		);
		const data = await CustomApi<{ store_id: number }>(
			"product/index",
			{
				store_id: cookies.store_id,
			},
			cookies.auth_id,
			cookies.customer_id
		);
		if (data.status !== 200 || categoryData.status !== 200) {
			navigate("/error");
		}
		setCategory(categoryData.data.responseData);
		setResponseData(data.data.responsData);
		setProductList(data.data.responsData);
		setProductLength(data.data.responsData.length);

		dispatch({ type: "loading", payload: false });
	}, [
		cookies.store_id,
		cookies.auth_id,
		cookies.customer_id,
		dispatch,
		navigate,
	]);
	const currentProductLists = useCallback(() => {
		let pageList: item[] = [];

		productList.map((item, i) => {
			// 表示分のみをフィルターして表示する
			// (ページ1 -1 )*10 = 0
			// ページ1 * 10 -1 = 9
			// 0番目から表示されるため最大数より1件ぶん引く
			if (
				(currentPage - 1) * maxProductView <= i &&
				currentPage * maxProductView - 1 >= i
			) {
				pageList.push(item);
			}
		});
		setShowProductList(pageList);
	}, [currentPage, productList]);
	useEffect(() => {
		getProductLists();
	}, [cookies.store_id, getProductLists]);
	useEffect(() => {
		currentProductLists();
	}, [productList, currentPage, currentProductLists]);
	const changeCategory = (type: string = "0") => {
		const fillData =
			type === "0"
				? responseData
				: responseData.filter(
						(item) => item.product_category_id === Number(type)
				  );
		setProductList(fillData);
		setProductLength(fillData.length);
		setCurrentPage(1);
		currentProductLists();
	};
	const dialogSuccess = async () => {
		setDialogType("stripe");
	};
	const clickOrder = (item: item) => {
		setDialog(true);
		setPropsDialog(item);
	};

	const closeDialog = () => {
		getProductLists();
		setDialog(!dialog);
		setDialogType("orderConfirm");
		setOrderMessage("");
		setOrderItemQuantity(1);
	};

	return (
		<div className="cont_wrapper">
			<div className="product_top_buttons">
				<div className="select_wrap">
					<select onChange={(e) => changeCategory(e.target.value)}>
						<option value="0">全ての商品</option>
						{category.map((item, i) => (
							<option key={i} value={item.id}>
								{item.category_name}
							</option>
						))}
					</select>
				</div>
			</div>
			{showProductList.map((item, i) => (
				<ProductItem item={item} clickOrder={clickOrder} />
			))}
			{productLength > maxProductView ? (
				<Pagination
					currentPage={currentPage}
					pageLength={productLength}
					maxView={maxProductView}
					setCurrent={setCurrentPage}
				/>
			) : null}
			{dialog ? (
				<BaseDialog
					setOrderMessage={setOrderMessage}
					setOrderItemQuantity={setOrderItemQuantity}
					orderMessage={orderMessage}
					orderItemQuantity={orderItemQuantity}
					type={dialogType}
					orderItem={propsDialog}
					close={closeDialog}
					changeType={setDialogType}
					success={() => dialogSuccess()}
				/>
			) : (
				""
			)}
		</div>
	);
};
