import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { profileEdit } from "../types";
import { Title } from "../components/common/title";
import { BaseDialog } from "../components/dialog/baseDialog";

import axios from "axios";
import { CustomApi } from "../hooks/useAPi";
import { useDispatch } from "react-redux";
import {
	requiredError,
	maxLengthError,
	patternNumber,
	patternHankaku,
	patternPhoneNumber,
	patternAddress,
} from "../const/validationMessage";
import { useCookies } from "react-cookie";

export const ProfileEdit = () => {
	const params = useParams();

	const navigate = useNavigate();

	const dispatch = useDispatch();
	const [cookies] = useCookies(["customer_id", "auth_id"]);
	const [dialog, setDialog] = useState<boolean>(false);
	const [beforeEmail, setBeforeEmail] = useState<profileEdit["email"]>("");
	const [afterEmail, setAfterEmail] = useState<profileEdit["email"]>("");
	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
		setValue,
		reset,
	} = useForm<profileEdit>({
		mode: "onBlur",
		criteriaMode: "all",
	});
	const getProfile = async () => {
		dispatch({ type: "loading", payload: true });
		const data: any = await CustomApi<{ id: number }>(
			"customer/show",
			{
				id: cookies.customer_id,
			},
			cookies.auth_id,
			cookies.customer_id
		);
		if (data.status !== 200) {
			navigate("/error");
		}
		reset(data.data.responsData);
		setBeforeEmail(data.data.responsData.email);
		dispatch({ type: "loading", payload: false });
	};
	const onSubmit = async (values: profileEdit) => {
		dispatch({ type: "loading", payload: true });
		const data = await CustomApi<profileEdit>(
			"customer/update",
			values,
			cookies.auth_id,
			cookies.customer_id
		);
		if (data.status !== 200) {
			navigate("/error");
		}
		setAfterEmail(data.data.responsData.email);
		dispatch({ type: "loading", payload: false });
		setDialog(true);
	};

	// const getAddress = async () => {
	// 	const res = await axios.get(
	// 		"https://api.zipaddress.net/?zipcode=" + getValues("post_number")
	// 	);
	// 	if (res.data.code === 200) {
	// 		const { pref, address } = res.data.data;

	// 		setValue("prefecture", pref);
	// 		setValue("municipalities", address);
	// 	} else {
	// 		alert("郵便番号が見つかりません");
	// 	}
	// };
	const closeDialog = () => {
		setDialog(false);
		navigate(
			beforeEmail === afterEmail ? `/${params.store}` : `/${params.store}/login`
		);
	};
	useEffect(() => {
		getProfile();
	}, []);
	return (
		<div className="cont_wrapper">
			<Title title={"プロフィール編集"} />
			<form>
				<div>
					<div className="form-title">
						<span className="required">必須</span>
						<label>お名前</label>
					</div>
					{errors.name && <p className="alert">{errors.name?.message}</p>}
					<input
						{...register("name", {
							required: requiredError,
							maxLength: maxLengthError(16),
						})}
						type="text"
					/>
				</div>
				<div>
					<div className="form-title">
						<span className="required">必須</span>
						<label>ニックネーム</label>
					</div>

					{errors.nickname && (
						<p className="alert">{errors.nickname?.message}</p>
					)}
					<input
						{...register("nickname", {
							required: requiredError,
							maxLength: maxLengthError(16),
						})}
						type="text"
					/>
				</div>
				<div>
					<div className="form-title">
						<span className="any">任意</span>
						<label>TwitterID</label>
					</div>

					{errors.twitterid && (
						<p className="alert">{errors.twitterid?.message}</p>
					)}
					<input
						{...register("twitterid", {
							maxLength: maxLengthError(15),
							pattern: patternHankaku,
						})}
						type="text"
						src=""
						placeholder=""
						alt=""
					/>
				</div>
				<div>
					<div className="form-title">
						<span className="any">任意</span>
						<label>InstagramID</label>
					</div>

					{errors.instagramid && (
						<p className="alert">{errors.instagramid?.message}</p>
					)}
					<input
						{...register("instagramid", {
							maxLength: maxLengthError(30),
							pattern: patternHankaku,
						})}
						type="text"
						src=""
						placeholder=""
						alt=""
					/>
				</div>
				{/* 電話番号 */}
				<div>
					<div className="form-title">
						<span className="required">必須</span>
						<label>電話番号(ハイフンなし)</label>
					</div>
					{errors.phone_number && (
						<p className="alert">{errors.phone_number?.message}</p>
					)}
					<input
						{...register("phone_number", {
							required: requiredError,
							pattern: patternPhoneNumber,
						})}
						type="text"
						src=""
						placeholder="電話番号 を入力してください"
						alt=""
					/>
				</div>
				{/* メールアドレス */}
				<div>
					<div className="form-title">
						<span className="required">必須</span>
						<label>メールアドレス</label>
					</div>
					{errors.email && <p className="alert">{errors.email?.message}</p>}
					<input
						{...register("email", {
							required: requiredError,
							maxLength: maxLengthError(255),
							pattern: patternAddress,
						})}
						type="text"
						src=""
						placeholder="メールアドレス を入力してください"
						alt=""
					/>
				</div>
				<div>
					<div className="postal_code">
						<div className="form-title">
							<span className="required">必須</span>
							<label htmlFor="zipCode" className="form-label">
								郵便番号(ハイフンなし)
							</label>
						</div>
						{errors.post_number && (
							<p className="alert">{errors.post_number?.message}</p>
						)}
						<div className="form_post_flex">
							<input
								{...register("post_number", {
									required: requiredError,
									maxLength: maxLengthError(7),
									pattern: patternNumber,
								})}
								type="text"
								placeholder="例：1234567"
							/>
							{/* <button
								type="button"
								onClick={() => getAddress()}
								className="outline_pink_btn"
							>
								住所検索
							</button> */}
						</div>
					</div>
				</div>
				{/* <div>
					<div className="form-title">
						<span className="required">必須</span>
						<label>都道府県</label>
					</div>
					{errors.prefecture && (
						<p className="alert">{errors.prefecture?.message}</p>
					)}
					<input
						{...register("prefecture", {
							required: requiredError,
							maxLength: maxLengthError(50),
						})}
						type="text"
						src=""
						placeholder=""
						alt=""
					/>
				</div>
				<div>
					<div className="form-title">
						<span className="required">必須</span>
						<label>市区町村（番地・建物名は任意）</label>
					</div>
					{errors.municipalities && (
						<p className="alert">{errors.municipalities?.message}</p>
					)}
					<input
						{...register("municipalities", {
							required: requiredError,
							maxLength: maxLengthError(50),
						})}
						type="text"
						src=""
						placeholder=""
						alt=""
					/>
				</div> */}
			</form>
			<div className="button_flex">
				<Link to={`/${params.store}/`} className="outline_pink_btn">
					キャンセル
				</Link>
				<button
					onClick={handleSubmit(onSubmit)}
					type="submit"
					className="normal_btn"
				>
					更新
				</button>
			</div>
			{dialog ? <BaseDialog type="complete" close={() => closeDialog()} /> : ""}
		</div>
	);
};
