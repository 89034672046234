export const requiredError = "入力してください";
export const consentError = "同意は必須になります";
export const minLengthError = (v: number) => {
  return { value: v, message: `${v}文字以上で入力してください。` };
};
export const maxLengthError = (v: number) => {
  return { value: v, message: `${v}文字以内で入力してください。` };
};
export const maxLengthNumberError = (v: number) => {
  return { value: v, message: `${v}桁以内で入力してください。` };
};

export const patternNumber = {
  value: /^[0-9]+$/,
  message: "半角数字で入力してください。",
};

export const patternHankaku = {
  value: /^[a-zA-Z0-9]+$/,
  message: "半角英数字で入力してください。",
};

export const patternZenkaku = {
  value: /^[ぁ-んァ-ンa-zA-Z亜-熙\-]+$/,
  message: "全角文字で入力してください。",
};

export const patternKatakana = {
  value: /^[ァ-ヶー　\s]*$/,
  message: "カタカナで入力してください。",
};

export const patternAddress = {
  value: /^\S+@\S+\.\S+$/,
  message: "メールアドレス形式で入力してください。",
};

export const patternPhoneNumber = {
  value: /^0\d{9,10}$/,
  message: "電話番号の形式で入力してください。",
};

export const patternUrl = {
  value: /^[0-9a-zA-Z\-_]*$/,
  message: "半角英数字（-と_含む）で入力してください。",
};

export const diffPasswordError = "パスワードが一致しません";
