import { useEffect, useState, useCallback } from "react";
import close_icon from "../../assets/img/close_icon.svg";

import menu_icon from "../../assets/img/menu_icon.svg";
import logo from "../../assets/img/logo.svg";
import user from "../../assets/img/user_edit.svg";
import shop from "../../assets/img/shop.svg";
import arrowBottom from "../../assets/img/arrow_bottom.svg";
import { Link, Outlet, useParams, useNavigate } from "react-router-dom";
import { BaseDialog } from "../dialog/baseDialog";
import { useCookies } from "react-cookie";
import { CustomApi } from "../../hooks/useAPi";
import { typeShop } from "../../types";

export const Header = () => {
	const params = useParams();
	const navigate = useNavigate();

	const [cookies, setCookie] = useCookies([
		"store_name",
		"auth_id",
		"customer_id",
		"store_url",
		"store_id",
	]);

	const [toggleMenu, setToggleMenu] = useState<boolean>(false);
	const [dialog, setDialog] = useState<boolean>(false);
	const [toggleShopList, setToggleShopList] = useState<boolean>(false);
	const [shopList, setShopList] = useState<typeShop[]>([]);
	/**
	 * メニュー開閉イベント
	 */
	const toggle = () => {
		setToggleMenu(!toggleMenu);
	};
	/**
	 * メニュー項目オブジェクト
	 */
	const menuList = [
		{
			link: "/",
			name: "商品一覧",
		},
	];
	const onChangeStore = useCallback(
		(storeUrl: string, storeId: number, storeName: string) => {
			setToggleShopList(false);
			setCookie("store_name", storeName);
			setCookie("store_url", storeUrl);
			setCookie("store_id", storeId);
			navigate(`/${storeUrl}/`);
		},
		[setCookie, navigate]
	);
	useEffect(() => {
		const f = async () => {
			const res = await CustomApi<{}>(
				"store/index",
				{},
				cookies.auth_id,
				cookies.customer_id
			);
			if (res.status === 200) {
				setShopList(res.data.responsData);
			}
		};
		f();
	}, [cookies.auth_id, cookies.customer_id]);
	return (
		<>
			<header id="header">
				<div className="header_top">
					<img src={logo} className="header_icon" alt="" />
					<div className="header-center">
						<p>{cookies.store_name}</p>
					</div>

					<div className="header-right">
						<button
							onClick={() => setToggleShopList((prev) => !prev)}
							className={`select_shop_pc ${toggleShopList ? "active" : ""}`}
						>
							<svg>
								<use xlinkHref={shop + "#root"} />
							</svg>
							<p>店舗選択</p>
						</button>
						<div id="hamburger">
							<img
								onClick={toggle}
								className={`menu_icon ${toggleMenu ? "hidden" : ""}`}
								src={menu_icon}
								alt=""
							/>
							<img
								onClick={toggle}
								className={`close ${toggleMenu ? "" : "hidden"}`}
								src={close_icon}
								alt=""
							/>
						</div>
					</div>
					<div className={`menu ${toggleMenu ? "" : "hidden"}`}>
						<div className="menu_wrapper">
							<div className="button_area">
								<Link
									onClick={toggle}
									className="edit_button"
									to={`/${params.store}/profile_edit`}
								>
									<svg>
										<use xlinkHref={user + "#root"} />
									</svg>
									<p>プロフィール編集</p>
								</Link>
							</div>
							<ul>
								{menuList!.map((item, i) => (
									<li key={i}>
										<Link onClick={toggle} to={`/${params.store}${item.link}`}>
											<div className="arrow arrow-right"></div>
											{item.name}
										</Link>
									</li>
								))}
								<li>
									<p
										onClick={() => {
											toggle();
											setDialog(true);
										}}
									>
										<div className="arrow arrow-right"></div>
										ログアウト
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</header>
			<button
				onClick={() => setToggleShopList((prev) => !prev)}
				className="select_shop_sp"
			>
				<span>
					<svg className="icon_shop">
						<use xlinkHref={shop + "#root"} />
					</svg>
				</span>
				<span>店舗選択</span>
				<div>
					<svg
						className={`icon_arrow_bottom ${toggleShopList ? "active" : ""}`}
					>
						<use xlinkHref={arrowBottom + "#root"} />
					</svg>
				</div>
			</button>
			<div id="shopListModal" className={toggleShopList ? "active" : ""}>
				{shopList.length === 0 ? (
					<p className="shopListNoItem">店舗がありません</p>
				) : (
					<ul>
						{shopList.map((item) => (
							<li
								className="shopListItem"
								key={item.id}
								onClick={() =>
									onChangeStore(item.store_url, item.id, item.store_name)
								}
							>
								<p>{item.store_name}</p>
								<p>〒{item.post_number}</p>
								{/* <p>
									{item.prefectures}
									{item.municipalities}
									{item.streets}
									{item.building}
								</p> */}
							</li>
						))}
					</ul>
				)}
			</div>
			{dialog ? (
				<BaseDialog type="logout" close={() => setDialog(false)} />
			) : (
				""
			)}
			<Outlet />
		</>
	);
};
