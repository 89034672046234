import { useEffect } from "react";

export const Loading = () => {
	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.removeAttribute("style");
		};
	}, []);
	return (
		<div id="loading">
			<div className="loadingio-spinner-rolling-09ckrq18eb7s">
				<div className="ldio-yr7rj8lblh">
					<div></div>
				</div>
			</div>
		</div>
	);
};
