import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

type props = {
	element: React.ReactNode;
};
export const RouteAuthGuard = (props: props) => {
	const params = useParams();
	const [cookies] = useCookies(["customer_id"]);

	if (cookies.customer_id) {
		return <>{props.element}</>;
	} else {
		return <Navigate to={`/${params.store}/login`} replace={false} />;
	}
};
