import { createStore, compose } from "redux";
type action = {
	type: "loading";
	payload: any;
};
export type state = {
	loading: boolean;
};
const initialState: state = {
	loading: false,
};
interface ExtendedWindow extends Window {
	__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare var window: ExtendedWindow;

const composeReduxDevToolsEnhancers =
	(typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose;

const reducer = (state: state = initialState, action: action): any => {
	switch (action.type) {
		case "loading":
			return {
				...state,
				loading: (state.loading = action.payload),
			};
		default:
			return state;
	}
};

const store = createStore(reducer, composeReduxDevToolsEnhancers());

export default store;
