import logo from "../../assets/img/logo.svg";
import { Outlet } from "react-router-dom";

export const HeaderSub = () => {
	return (
		<div className="encas_cont_wrap">
			<header id="header_sub">
				<img src={logo} className="header_icon" alt="" />
			</header>
			<Outlet />
		</div>
	);
};
