import React, { useState } from "react";
import { ResetMail } from "../components/login/resetMail";
import { ResetMailComplete } from "../components/login/resetMailComplete";
export const PassResetMail = () => {
	const [sendMail, setSendMail] = useState<Boolean>(false);
	return (
		<div className="cont_wrapper">
			{sendMail ? (
				<ResetMailComplete />
			) : (
				<ResetMail changeState={() => setSendMail(true)} />
			)}
		</div>
	);
};
