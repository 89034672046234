import { SetStateAction, useState } from "react";
import { item } from "../../types/index";
import { Title } from "../common/title";
import {
	useElements,
	useStripe,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";
import { CustomApi } from "../../hooks/useAPi";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import master from "../../assets/img/mc_symbol_opt_73_3x.png";
import visa from "../../assets/img/Visa_Brandmark_Blue_RGB_2021.png";
import amex from "../../assets/img/amex-logomark-img-06.png";
import jcb from "../../assets/img/jcb.png";
import dinars from "../../assets/img/diners.png";
type props = {
	orderItem: item;
	close: () => void;
	orderMessage?: SetStateAction<string>;
	orderItemQuantity?: SetStateAction<number>;
	changeType?: (i: "complete" | "failure") => void;
};
export const StripeDialog = (props: props) => {
	const dispatch = useDispatch();
	const [cookies] = useCookies(["customer_id", "store_id", "auth_id"]);
	const stripe = useStripe();
	const elements = useElements();
	const [cardError, setCardError] = useState<{
		complete: boolean;
		message: string;
	}>({ complete: false, message: "" });
	const [expiryError, setExpiryError] = useState<{
		complete: boolean;
		message: string;
	}>({ complete: false, message: "" });
	const [cvcError, setCvcError] = useState<{
		complete: boolean;
		message: string;
	}>({ complete: false, message: "" });
	const handlePayment = async (data: any) => {
		dispatch({ type: "loading", payload: true });

		const IntentsRes: any = await CustomApi<{
			amount: number;
			username: string;
			quantity: SetStateAction<number>;
		}>(
			"createPayment",
			{
				amount: props.orderItem.amount,
				quantity: props.orderItemQuantity!,
				username: cookies.customer_id,
			},
			cookies.auth_id,
			cookies.customer_id
		);
		if (IntentsRes.status === 200) {
			const { paymentIntent, error } = await stripe!.confirmCardPayment(
				IntentsRes.data.client_secret,
				{
					payment_method: {
						card: elements!.getElement("cardNumber")!,
						billing_details: {
							name: cookies.customer_id,
						},
					},
				}
			);
			if (error) {
				dispatch({ type: "loading", payload: false });
				props.changeType!("failure");
			} else if (paymentIntent && paymentIntent.status === "succeeded") {
				const createOrderRes = await CustomApi<{
					store_id: number;
					customer_id: number;
					product_id: number;
					payment_id: string;
					message: SetStateAction<string>;
					quantity: SetStateAction<number>;
				}>(
					"createPaymentOrder",
					{
						store_id: Number(cookies.store_id),
						customer_id: Number(cookies.customer_id),
						product_id: props.orderItem.id,
						payment_id: paymentIntent!.id,
						message: props.orderMessage!,
						quantity: props.orderItemQuantity!,
					},
					cookies.auth_id,
					cookies.customer_id
				);
				dispatch({ type: "loading", payload: false });
				if (createOrderRes.status === 200) {
					props.changeType!("complete");
				} else {
					props.changeType!("failure");
				}
			}
		} else {
			dispatch({ type: "loading", payload: false });
			props.changeType!("failure");
		}
	};
	const changeCardValue = (data: any) => {
		if (data.error) {
			setCardError({ complete: data.complete, message: data.error.message });
		} else {
			setCardError({ complete: data.complete, message: "" });
		}
	};
	const changeExpiryValue = (data: any) => {
		if (data.error) {
			setExpiryError({ complete: data.complete, message: data.error.message });
		} else {
			setExpiryError({ complete: data.complete, message: "" });
		}
	};
	const changeCvcValue = (data: any) => {
		if (data.error) {
			setCvcError({ complete: data.complete, message: data.error.message });
		} else {
			setCvcError({ complete: data.complete, message: "" });
		}
	};
	return (
		<div id="stripeDialog" className="confirm_dialog">
			<Title title="カード決済" />
			<div className="available_cards">
				<p>ご利用可能なカード</p>
				<div className="cards_icon">
					<img src={master} alt="" />
					<img src={visa} alt="" />
					<img className="amex" src={amex} alt="" />
					<img src={jcb} alt="" />
					<img src={dinars} alt="" />
				</div>
			</div>
			<div className="form_item">
				<div className="form-title">
					<span className="required">必須</span>
					<label>カード番号</label>
				</div>
				<CardNumberElement
					options={{
						iconStyle: "solid",
						style: {
							base: {
								fontSize: "16px",
								letterSpacing: "1px",
								color: "#b0b0ae",
								"::placeholder": {
									color: "#b0b0ae",
								},
							},
						},
					}}
					onChange={changeCardValue}
				/>
				{cardError.message ? <p className="alert">{cardError.message}</p> : ""}
			</div>
			<div className="form_item">
				<div className="form-title">
					<span className="required">必須</span>
					<label>有効期限</label>
				</div>
				<CardExpiryElement
					options={{
						style: {
							base: {
								fontSize: "16px",
								letterSpacing: "1px",
								color: "#b0b0ae",
								"::placeholder": {
									color: "#b0b0ae",
								},
							},
						},
					}}
					onChange={changeExpiryValue}
				/>
				{expiryError.message ? (
					<p className="alert">{expiryError.message}</p>
				) : (
					""
				)}
			</div>
			<div className="form_item">
				<div className="form-title">
					<span className="required">必須</span>
					<label>CVC情報</label>
				</div>
				<CardCvcElement
					options={{
						style: {
							base: {
								fontSize: "16px",
								letterSpacing: "1px",
								color: "#b0b0ae",
								"::placeholder": {
									color: "#b0b0ae",
								},
							},
						},
					}}
					onChange={changeCvcValue}
				/>
				{cvcError.message ? <p className="alert">{cvcError.message}</p> : ""}
			</div>
			<div className="gray_line"></div>

			<div className="button_flex">
				<button onClick={() => props.close()} className="outline_pink_btn">
					キャンセル
				</button>
				<button
					className="normal_btn"
					disabled={
						!stripe ||
						!elements ||
						!cardError.complete ||
						!expiryError.complete ||
						!cvcError.complete
					}
					onClick={handlePayment}
				>
					決済
				</button>
			</div>
		</div>
	);
};
