import axios from "axios";
export const CustomApi = async <T,>(
	url: string,
	param: T,
	auth_id: string,
	customer_id: number
) => {
	try {
		const response = await axios.post<T>(
			`${process.env.REACT_APP_BASE_API_URL}${url}`,
			param,
			{
				headers: {
					"auth-id": auth_id,
					"type-id": "customer",
					"user-id": customer_id,
				},
			}
		);
		return response;
	} catch (error: any) {
		console.error(error);
		return error.response!;
	}
};
