import { Link, useParams } from "react-router-dom";
export const LinePayComplate = () => {
	const params = useParams();

	return (
		<div id="payComplete" className="cont_wrapper">
			<div>
				<h3>決済が完了しました。</h3>
				<Link className="normal_btn" to={`/${params.store}/`}>
					商品一覧へ
				</Link>
			</div>
		</div>
	);
};
