import { Title } from "../../components/common/title";

export const Tokushoho = () => {
  const handleClick = () => {
    window.close();
  };

  return (
    <div id="tokushoho" className="cont_wrapper">
      <Title title={"特定商取引法に関する表記"} />

      <div className="top_detail">
        <div className="text_wrapper">
          <div className="top_text_flex">
            <table className="table">
              <tr>
                <th>販売業者</th>
                <td>株式会社NIG</td>
              </tr>
              <tr>
                <th>代表責任者</th>
                <td>西園寺泰道</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>
                  〒225-0023 <br></br>
                  神奈川県横浜市青葉区大場町364-41
                </td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>info@encas.jp</td>
              </tr>
              <tr>
                <th>ホームページURL</th>
                <td>http://www.nig.co.jp</td>
              </tr>
              <tr>
                <th>販売価格</th>
                <td>商品一覧ページをご参照ください。</td>
              </tr>
              <tr>
                <th>商品代金以外の必要料金</th>
                <td>消費税</td>
              </tr>
              <tr>
                <th>お支払方法</th>
                <td>クレジットカード決済</td>
              </tr>
              <tr>
                <th>商品の引き渡し時期</th>
                <td>
                  決済が完了した後、２営業日以内に店舗から顧客に直接引き渡し
                </td>
              </tr>
              <tr>
                <th>返品・交換・キャンセル等</th>
                <td>
                  商品注文決定後のキャンセル等はお受け致しかねます。<br></br>
                  商品が不良の場合のみ各店舗・各主催者が良品と交換致します。
                </td>
              </tr>
              <tr>
                <th>返品期限</th>
                <td>
                  商品が各店舗・各主催者にて提供されるまで。（ただし7日以内）
                </td>
              </tr>
              <tr>
                <th>備考</th>
                <td>
                  上記公開メールアドレスにご連絡いただき、解決が困難な場合は、こちらからお伝えするお電話もしくはオンラインでのご相談を承ります。
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button onClick={handleClick} className="normal_btn">
          閉じる
        </button>
      </div>
    </div>
  );
};
