import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
	Link,
	useParams,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { register } from "../types";
import { Title } from "../components/common/title";
import { BaseDialog } from "../components/dialog/baseDialog";

import axios from "axios";
import { CustomApi } from "../hooks/useAPi";
import { useDispatch } from "react-redux";
import {
	requiredError,
	minLengthError,
	maxLengthError,
	patternNumber,
	patternHankaku,
	patternPhoneNumber,
	diffPasswordError,
	consentError,
} from "../const/validationMessage";
import { useCookies } from "react-cookie";

export const SignUp = () => {
	const url = `${window.location.protocol}//${window.location.host}`;
	const [cookies] = useCookies(["customer_id", "auth_id"]);

	const params = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const [dialog, setDialog] = useState<boolean>(false);

	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
		setValue,
	} = useForm<register>({
		mode: "onBlur",
		criteriaMode: "all",
	});
	const onSubmit = async (values: register) => {
		values.id = Number(searchParams.get("id"));
		dispatch({ type: "loading", payload: true });
		const data = await CustomApi<register>(
			"customer/update",
			values,
			cookies.auth_id,
			cookies.customer_id
		);
		if (data.status !== 200) {
			navigate("/error");
		}
		setDialog(true);
		dispatch({ type: "loading", payload: false });
	};
	// const getAddress = async () => {
	// 	const res = await axios.get(
	// 		"https://api.zipaddress.net/?zipcode=" + getValues("post_number")
	// 	);
	// 	if (res.data.code === 200) {
	// 		const { pref, address } = res.data.data;

	// 		setValue("prefecture", pref);
	// 		setValue("municipalities", address);
	// 	} else {
	// 		alert("郵便番号が見つかりません");
	// 	}
	// };
	return (
		<>
			<div className="cont_wrapper">
				<Title title={"新規登録"} />
				<form>
					<div>
						<div className="form-title">
							<span className="required">必須</span>
							<label>お名前</label>
						</div>
						{errors.name && <p className="alert">{errors.name?.message}</p>}
						<input
							{...register("name", {
								required: requiredError,
								maxLength: maxLengthError(16),
							})}
							type="text"
						/>
					</div>
					<div>
						<div className="form-title">
							<span className="required">必須</span>
							<label>ニックネーム</label>
						</div>

						{errors.nickname && (
							<p className="alert">{errors.nickname?.message}</p>
						)}
						<input
							{...register("nickname", {
								required: requiredError,
								maxLength: maxLengthError(16),
							})}
							type="text"
						/>
					</div>
					<div>
						<div className="form-title">
							<span className="any">任意</span>
							<label>TwitterID</label>
						</div>

						{errors.twitterid && (
							<p className="alert">{errors.twitterid?.message}</p>
						)}
						<input
							{...register("twitterid", {
								maxLength: maxLengthError(15),
								pattern: patternHankaku,
							})}
							type="text"
							src=""
							placeholder=""
							alt=""
						/>
					</div>
					<div>
						<div className="form-title">
							<span className="any">任意</span>
							<label>InstagramID</label>
						</div>

						{errors.instagramid && (
							<p className="alert">{errors.instagramid?.message}</p>
						)}
						<input
							{...register("instagramid", {
								maxLength: maxLengthError(30),
								pattern: patternHankaku,
							})}
							type="text"
							src=""
							placeholder=""
							alt=""
						/>
					</div>

					<div>
						<div className="form-title">
							<span className="required">必須</span>
							<label>パスワード</label>
						</div>

						{errors.password && (
							<p className="alert">{errors.password?.message}</p>
						)}
						<input
							{...register("password", {
								required: requiredError,
								minLength: minLengthError(8),
								maxLength: maxLengthError(64),
								pattern: patternHankaku,
							})}
							type="password"
							src=""
							placeholder="半角英数字8文字以上"
							alt=""
						/>
					</div>
					<div>
						<div className="form-title">
							<span className="required">必須</span>
							<label>パスワード(確認)</label>
						</div>

						{errors.pass_confirm && (
							<p className="alert">{errors.pass_confirm?.message}</p>
						)}

						<input
							{...register("pass_confirm", {
								required: requiredError,
								minLength: minLengthError(8),
								maxLength: maxLengthError(64),
								pattern: patternHankaku,
								validate: (value) => {
									return value === getValues("password") || diffPasswordError;
								},
							})}
							type="password"
							src=""
							placeholder="半角英数字8文字以上"
							alt=""
						/>
					</div>

					{/* 電話番号 */}
					<div>
						<div className="form-title">
							<span className="required">必須</span>
							<label htmlFor="phone_number" className="form-label"></label>
							<p>電話番号(ハイフンなし)</p>
						</div>

						{errors.phone_number && (
							<p className="alert">{errors.phone_number?.message}</p>
						)}
						<input
							{...register("phone_number", {
								required: requiredError,
								pattern: patternPhoneNumber,
							})}
							type="text"
							src=""
							placeholder="電話番号 を入力してください"
							alt=""
						/>
					</div>

					<div>
						<div className="postal_code">
							<div className="form-title">
								<span className="required">必須</span>
								<label htmlFor="zipCode" className="form-label">
									郵便番号(ハイフンなし)
								</label>
							</div>
							{errors.post_number && (
								<p className="alert">{errors.post_number?.message}</p>
							)}
							<div className="form_post_flex">
								<input
									{...register("post_number", {
										required: requiredError,
										maxLength: maxLengthError(7),
										pattern: patternNumber,
									})}
									type="text"
									placeholder="例：1234567"
								/>
								{/* <button
									type="button"
									onClick={() => getAddress()}
									className="outline_pink_btn"
								>
									住所検索
								</button> */}
							</div>
						</div>
					</div>
					{/* <div>
						<div className="form-title">
							<span className="required">必須</span>
							<label>都道府県</label>
						</div>

						{errors.prefecture && (
							<p className="alert">{errors.prefecture?.message}</p>
						)}
						<input
							{...register("prefecture", {
								required: requiredError,
								maxLength: maxLengthError(50),
							})}
							type="text"
							src=""
							placeholder=""
							alt=""
						/>
					</div>
					<div>
						<div className="form-title">
							<span className="required">必須</span>
							<label>市区町村（番地・建物名は任意）</label>
						</div>

						{errors.municipalities && (
							<p className="alert">{errors.municipalities?.message}</p>
						)}
						<input
							{...register("municipalities", {
								required: requiredError,
								maxLength: maxLengthError(50),
							})}
							type="text"
							src=""
							placeholder=""
							alt=""
						/>
					</div> */}

					{/* 条項 */}
					{errors.consentFlg && (
						<p className="alert">{errors.consentFlg?.message}</p>
					)}
					<div className="term">
						<input
							{...register("consentFlg", {
								required: consentError,
							})}
							type="checkbox"
							id="consentFlg"
						/>
						<label htmlFor="consentFlg" className="checkbox"></label>
						<div className="terms_policy">
							<p>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={`${url}/${params.store}/service/policy`}
								>
									利用規約
								</a>
								と
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={`${url}/${params.store}/privacy/policy`}
								>
									プライバシーポリシー
								</a>
								を読み内容に同意します。
							</p>
						</div>
					</div>
					{errors.consentFlgOver20 && (
						<p className="alert">{errors.consentFlgOver20?.message}</p>
					)}
					<div className="term">
						<input
							{...register("consentFlgOver20", {
								required: consentError,
							})}
							type="checkbox"
							id="consentFlgOver20"
						/>
						<label htmlFor="consentFlgOver20" className="checkbox"></label>
						<div className="terms_policy">
							<p>私は20歳以上の成人です。</p>
						</div>
					</div>
				</form>
				<div className="button_flex">
					<Link to={`/${params.store}/login`} className="outline_pink_btn">
						キャンセル
					</Link>
					<button
						onClick={handleSubmit(onSubmit)}
						type="submit"
						className="normal_btn"
					>
						登録
					</button>
				</div>
				{dialog ? <BaseDialog type="completeSignUp" /> : ""}
			</div>
			<footer>
				<Link
					to={`/${params.store}/tokushoho`}
					target="_blank"
					style={{ color: " #ecc2c2", textDecoration: "underline" }}
				>
					特定商取引法に関する表記
				</Link>
			</footer>
		</>
	);
};
