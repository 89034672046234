import React from "react";
type props = {
	currentPage: number;
	pageLength: number;
	maxView: number;
	setCurrent: (i: number) => void;
};
export const Pagination = (props: props) => {
	const totalPage: number = Math.ceil(props.pageLength / props.maxView);
	let pageButtons = [];
	for (let i = 1; i <= totalPage; i++) {
		pageButtons.push(
			<div
				key={i}
				onClick={() => props.setCurrent(i)}
				className={`page_button ${props.currentPage === i ? "page_1" : ""}`}
			>
				<p>{i}</p>
			</div>
		);
	}
	return (
		<div className="change_page_buttons">
			{props.currentPage === 1 ? (
				""
			) : (
				<div
					onClick={() => props.setCurrent(props.currentPage - 1)}
					className="page_arrow arrow_left"
				></div>
			)}
			{pageButtons}

			{totalPage === props.currentPage ? (
				""
			) : (
				<div
					onClick={() => props.setCurrent(props.currentPage + 1)}
					className="page_arrow arrow_right"
				></div>
			)}
		</div>
	);
};
