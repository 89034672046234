import React from "react";
type props = {
	title: string;
};
export const Title = ({ title }: props) => {
	return (
		<div className="title">
			<h2>{title}</h2>
		</div>
	);
};
