import { RouteObject } from "react-router-dom";
import { Header } from "../components/header/header";
import { HeaderSub } from "../components/header/headerSub";
import { MailRegister } from "../pages/MailRegister";
import { SignUp } from "../pages/SignUp";
import { ProductList } from "../pages/ProductList";
import { Error } from "../pages/Error";
import { ProfileEdit } from "../pages/ProfileEdit";
import { PasswordReset } from "../pages/PasswordReset";
import { Login } from "../pages/Login";
import { RedirectPayment } from "../pages/RedirectPayment";
import { RouteAuthGuard } from "../components/common/routeAuthGuard";
import { LinePayComplate } from "../pages/LinePayComplate";
import { ServicePolicy } from "../pages/policy/ServicePolicy";
import { PrivacyPolicy } from "../pages/policy/PrivacyPolicy";
import { Tokushoho } from "../pages/policy/Tokushoho";
import { Top } from "../pages/Top";
import { PassResetMail } from "../pages/PassResetMail";
import { PassResetCompleted } from "../pages/PassResetCompleted";
export const routes: RouteObject[] = [
	{
		path: "/",
		element: <Top />,
	},
	{
		path: "/*",
		element: "404",
	},
	{
		path: "/:store",
		element: <Header />,
		children: [
			{
				path: "",
				element: <RouteAuthGuard element={<ProductList />} />,
			},
			{
				path: "profile_edit",
				element: <RouteAuthGuard element={<ProfileEdit />} />,
			},

			{
				path: "redirect_payment",
				element: <RedirectPayment />,
			},
			{
				path: "linepay_complate",
				element: <LinePayComplate />,
			},

			// TODO:Authのリダイレクト処理などのmiddleware追加
			// ナビ付き(ログイン後)のヘッダーを利用する場合はこっち
		],
	},
	{
		path: "/:store",
		element: <HeaderSub />,
		children: [
			// ナビなし(ログイン前)のヘッダーを利用する場合はこっち
			{
				path: "signup",
				element: <SignUp />,
			},
			{
				path: "mail/register",
				element: <MailRegister />,
			},
			{
				path: "mail/reset",
				element: <PassResetMail />,
			},
			{
				path: "login",
				element: <Login />,
			},
			{
				path: "service/policy", //利用規約
				element: <ServicePolicy />,
			},
			{
				path: "privacy/policy", //プライバシーポリシー
				element: <PrivacyPolicy />,
			},
			{
				path: "tokushoho", //特定商取引法に関する表記
				element: <Tokushoho />,
			},
			{
				path: "password_reset",
				element: <PasswordReset />,
			},
			{
				path: "password_reset/completed",
				element: <PassResetCompleted />,
			},
		],
	},
	{
		path: "/error",
		element: <Error />,
	},
];
