import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

export const Error = () => {
	const [cookies] = useCookies(["store_url"]);

	return (
		<div className="cont_wrapper">
			<div id="error">
				<h3>
					処理に問題が発生しました。
					<br />
					再度行ってください。
				</h3>
				<Link className="normal_btn" to={`/${cookies.store_url}/`}>
					商品一覧へ
				</Link>
			</div>
		</div>
	);
};
