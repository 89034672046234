import { SetStateAction, useEffect } from "react";
import { OrderConfirm } from "./orderConfirm";
import { CompleteDialog } from "./completeDialog";
import { item } from "../../types/index";
import { FailureDialog } from "./failureDialog";
import { CompleteSignUp } from "./completeSignUp";
import { LogoutDialog } from "./logoutDialog";
import { StripeDialog } from "./stripeDialog";
type props = {
	type:
		| "delete"
		| "deleteConfirm"
		| "complete"
		| "failure"
		| "paymentCompleteConfirm"
		| "orderConfirm"
		| "completeSignUp"
		| "logout"
		| "stripe";
	orderItem?: item;
	success?: () => void;
	close?: () => void;
	setOrderMessage?: (i: string) => void;
	setOrderItemQuantity?: (i: number) => void;
	changeType?: (i: "complete" | "failure") => void;
	orderMessage?: SetStateAction<string>;
	orderItemQuantity?: SetStateAction<number>;
};
export const BaseDialog = (props: props) => {
	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.removeAttribute("style");
		};
	}, []);

	const DialogInnerComponent = () => {
		switch (props.type) {
			case "deleteConfirm":
				return "deleteConfirm";
			case "failure":
				return <FailureDialog close={props.close!} />;
			case "paymentCompleteConfirm":
				return "deleteConfirm";
			case "orderConfirm":
				return (
					<OrderConfirm
						orderMessage={props.setOrderMessage}
						orderItemQuantity={props.setOrderItemQuantity}
						orderItem={props.orderItem!}
						success={props.success!}
						close={props.close!}
					/>
				);
			case "complete":
				return <CompleteDialog close={props.close!} />;
			case "completeSignUp":
				return <CompleteSignUp />;
			case "logout":
				return <LogoutDialog close={props.close!} />;
			case "stripe":
				return (
					<StripeDialog
						orderMessage={props.orderMessage}
						orderItemQuantity={props.orderItemQuantity}
						orderItem={props.orderItem!}
						close={props.close!}
						changeType={props.changeType!}
					/>
				);
		}
	};
	return (
		<div id="dialog">
			<div className="dialog_box">{DialogInnerComponent()}</div>
		</div>
	);
};
