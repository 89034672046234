import icon_mail from "../../assets/img/icon_mail.png";
import { Title } from "../../components/common/title";

/**
 * メール登録の送信オブジェクトの型定義
 */

export const ResetMailComplete = () => {
  return (
    <div className="mailComplete">
      <Title title={"メール送信完了"} />

      <div>
        <img className="iconMail" src={icon_mail} alt="" />
      </div>
      <div className="guide">
        <span>
          <p>入力したメールアドレス宛に</p>
          <p>パスワードリセットメールを送信しました。</p>
        </span>
      </div>
    </div>
  );
};
