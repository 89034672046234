import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

type props = {
	close: () => void;
};

export const LogoutDialog = (props: props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [cookies, setCookie, removeCookie] = useCookies([
		"customer_id",
		"store_name",
		"store_url",
		"store_id",
		"auth_id",
	]);
	const logout = () => {
		dispatch({ type: "loading", payload: true });

		removeCookie("customer_id");
		removeCookie("store_id");
		removeCookie("store_name");
		removeCookie("store_url");
		removeCookie("auth_id");
		dispatch({ type: "loading", payload: false });
		navigate(`/${params.store}/login`);
	};
	return (
		<div id="logout">
			<p className="alert">ログアウトしますか？</p>
			<div className="button_flex">
				<button onClick={() => props.close()} className="outline_pink_btn">
					キャンセル
				</button>
				<button onClick={() => logout()} type="submit" className="normal_btn">
					ログアウト
				</button>
			</div>
		</div>
	);
};
