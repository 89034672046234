import { Title } from "../components/common/title";
import {
	requiredError,
	minLengthError,
	maxLengthError,
	patternHankaku,
	diffPasswordError,
} from "../const/validationMessage";
import { useForm } from "react-hook-form";
import { CustomApi } from "../hooks/useAPi";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

type passwordReset = {
	id: number;
	param: string;
	password: string;
	password_confirm: string;
};

export const PasswordReset = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const params = useParams();
	const [cookies] = useCookies(["customer_id", "auth_id"]);

	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
	} = useForm<passwordReset>({
		mode: "onBlur",
		criteriaMode: "all",
	});
	const onSubmit = async (values: passwordReset) => {
		dispatch({ type: "loading", payload: true });
		values.id = Number(searchParams.get("id"));
		values.param = searchParams.get("param")!;
		const data = await CustomApi<passwordReset>(
			"customer/password_reset",
			values,
			cookies.auth_id,
			cookies.customer_id
		);
		if (data.status !== 200) {
			alert("なんらかの問題が発生しました。再度送信を行ってください");
			window.location.reload();
			return;
		}
		navigate(`/${params.store}/password_reset/completed`);

		dispatch({ type: "loading", payload: false });
	};
	return (
		<div className="cont_wrapper">
			<Title title={"パスワード再設定"} />
			<form>
				<div>
					<div className="form-title">
						<span className="required">必須</span>
						<label>新しいパスワード</label>
					</div>
					{errors.password && (
						<p className="alert">{errors.password?.message}</p>
					)}
					<input
						{...register("password", {
							required: requiredError,
							minLength: minLengthError(8),
							maxLength: maxLengthError(64),
							pattern: patternHankaku,
						})}
						type="password"
					/>
				</div>
				<div>
					<div className="form-title">
						<span className="required">必須</span>
						<label>新しいパスワード(確認)</label>
					</div>
					{errors.password_confirm && (
						<p className="alert">{errors.password_confirm?.message}</p>
					)}
					<input
						{...register("password_confirm", {
							required: requiredError,
							minLength: minLengthError(8),
							maxLength: maxLengthError(64),
							pattern: patternHankaku,
							validate: (value) => {
								return value === getValues("password") || diffPasswordError;
							},
						})}
						type="password"
					/>
				</div>
			</form>
			<button
				onClick={handleSubmit(onSubmit)}
				type="submit"
				className="normal_btn"
			>
				設定する
			</button>
		</div>
	);
};
