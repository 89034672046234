import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import {
	requiredError,
	minLengthError,
	maxLengthError,
	patternHankaku,
	patternAddress,
} from "../const/validationMessage";
import { login } from "../types";
import { Title } from "../components/common/title";
import { CustomApi } from "../hooks/useAPi";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

export const Login = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [cookies, setCookie] = useCookies([
		"customer_id",
		"store_name",
		"store_url",
		"store_id",
		"auth_id",
	]);
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm<login>({
		mode: "onChange",
		criteriaMode: "all",
	});

	//ログイン成功
	const onSubmit: SubmitHandler<login> = async (values: login) => {
		dispatch({ type: "loading", payload: true });
		let limit = new Date();
		// ログイン状態を1週間指定する
		limit.setDate(limit.getDate() + 7);
		values.store_url = params.store!;

		const data = await CustomApi<login>(
			"customer/login",
			values,
			cookies.auth_id,
			cookies.customer_id
		);
		if (data.status !== 200) {
			alert("ログインに失敗しました。再度やり直してください");
			reset();
			dispatch({ type: "loading", payload: false });
			return;
		}
		setCookie("customer_id", data.data.customer_id, {
			expires: limit,
		});
		setCookie("store_name", data.data.store_name, {
			expires: limit,
		});
		setCookie("store_url", data.data.store_url, {
			expires: limit,
		});
		setCookie("store_id", data.data.store_id, {
			expires: limit,
		});
		setCookie("auth_id", data.data.auth_id, {
			expires: limit,
		});

		dispatch({ type: "loading", payload: false });
		navigate(`/${params.store}/`);
	};

	return (
		<>
			<div className="cont_wrapper">
				<Title title={"ログイン"} />

				<form onSubmit={handleSubmit(onSubmit)}>
					{/* メールアドレス */}
					<div>
						<div className="form-title">
							<span className="required">必須</span>
							<label htmlFor="email">メールアドレス</label>
						</div>
						{errors.email && <p className="alert">{errors.email?.message}</p>}
						<input
							{...register("email", {
								required: requiredError,
								maxLength: maxLengthError(255),
								pattern: patternAddress,
							})}
							type="email"
							id="email"
							src=""
							placeholder="メールアドレス を入力してください"
							alt=""
						/>
					</div>
					{/* パスワード */}
					<div>
						<div className="form-title">
							<span className="required">必須</span>
							<label htmlFor="password">パスワード</label>
						</div>

						{errors.password && (
							<p className="alert">{errors.password?.message}</p>
						)}
						<input
							{...register("password", {
								required: requiredError,
								minLength: minLengthError(8),
								maxLength: maxLengthError(64),
								pattern: patternHankaku,
							})}
							type="password"
							id="password"
							src=""
							placeholder="半角英数字8桁以上 で入力してください"
							alt=""
						/>
					</div>
				</form>
				<div className="button_center">
					<button
						type="submit"
						className="normal_btn"
						onClick={handleSubmit(onSubmit)}
					>
						ログイン
					</button>
				</div>
				<div>
					<div>
						<div className="login_option">
							<nav>
								<p>
									パスワードを忘れた方は
									<Link to={`/${params.store}/mail/reset`}>こちら</Link>
								</p>
								<p>
									新規登録は
									<Link to={`/${params.store}/mail/register`}>こちら</Link>
								</p>
							</nav>
						</div>
					</div>
				</div>
				<footer>
					<Link
						to={`/${params.store}/tokushoho`}
						target="_blank"
						style={{ color: " #ecc2c2", textDecoration: "underline" }}
					>
						特定商取引法に関する表記
					</Link>
				</footer>
			</div>
		</>
	);
};
