import logo from "../assets/img/logo.svg";
// import mobile from "../assets/img/mobile.svg";
// import payment from "../assets/img/payment.svg";
import { Footer } from "../components/footer/Footer";

export const Top = () => {
  return (
    <>
      <div className="top_wrapper">
        <h2>EnCas(エンキャス)とは？</h2>
        <img src={logo} className="store_top_icon" alt="" />
        <div className="top_detail">
          <div className="text_wrapper">
            <div className="top_text_flex">
              <div className="pink_circle"></div>
              <p>
                飲食店、特にコンセプトバーカジュアルバー及びコンセプトカフェ・ホストクラブ・キャバクラなどを中心に「仕事で行けない」「推したい」「サプライズしたい」Etcの理由で、実店舗に行けないときに遠隔でドリンクを投げ込むことができます。
              </p>
            </div>
          </div>

          <div className="text_wrapper">
            <div className="top_text_flex">
              <div className="pink_circle"></div>
              <p>
                遠隔オーダーコミュニティサポートになります。
                <br />
                ・お店の周年
                <br />
                ・担当、推しのバースデー
                <br />
                ・お店のイベント
                <br />
                ・コンカフェゲスト出勤
                <br />
                などの際キャスト、ゲスト問わずにオーダーをプレゼントすることができます。
              </p>
            </div>
          </div>

          <div className="text_wrapper">
            <div className="top_text_flex">
              <div className="pink_circle"></div>
              <p>各店舗ごとのQRコードでログインしてください。</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
