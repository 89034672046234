import React, { useState } from "react";
import { routes } from "./routes/routes";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loading } from "./pages/Loading";
import store, { state } from "./store/index";
const App = () => {
	const loading: any = useSelector<state>((store) => store.loading);
	const router = useRoutes(routes);
	return (
		<>
			{loading ? <Loading /> : ""}
			<div className="wrapper">{router}</div>
		</>
	);
};

export default App;
