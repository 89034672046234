import { Title } from "../../components/common/title";
import { useForm } from "react-hook-form";
import {
	requiredError,
	maxLengthError,
	patternAddress,
} from "../../const/validationMessage";
import { CustomApi } from "../../hooks/useAPi";
import { useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useCookies } from "react-cookie";

/**
 * メール登録オブジェクトの型定義
 */
type mailRegister = {
	email: string;
	store_url: string;
};
type props = {
	changeState: () => void;
};
export const ResetMail = (props: props) => {
	const [cookies] = useCookies(["customer_id", "auth_id"]);
	const params = useParams();

	const dispatch = useDispatch();
	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
		trigger,
	} = useForm<mailRegister>({
		criteriaMode: "all",
	});
	const onSubmit = async (values: mailRegister) => {
		values.store_url = params.store!;
		dispatch({ type: "loading", payload: true });

		const data = await CustomApi<mailRegister>(
			"customer/request_password_reset",
			values,
			cookies.auth_id,
			cookies.customer_id
		);
		if (data.status !== 200) {
			alert("なんらかの問題が発生しました。再度送信を行ってください");
			window.location.reload();
			return;
		}
		dispatch({ type: "loading", payload: false });
		// 完了したら画面切り替え
		props.changeState();
	};
	return (
		<>
			<Title title={"パスワードリセット"} />
			<form>
				<div>
					<p>リセットするアカウントのメールアドレスを入力してください</p>
				</div>
				<div className="form-title">
					<span className="required">必須</span>
					<label htmlFor="email">メールアドレス</label>
				</div>
				{errors.email && <p className="alert">{errors.email?.message}</p>}
				<input
					{...register("email", {
						required: requiredError,
						maxLength: maxLengthError(255),
						pattern: patternAddress,
						onBlur: () => {
							if (getValues("email")) {
								trigger("email");
							}
						},
						validate: (value) => {
							return (
								value === getValues("email") || "メールアドレスが一致しません"
							);
						},
					})}
					type="email"
					id="email"
					src=""
					placeholder="メールアドレス を入力してください"
					alt=""
				/>
			</form>
			<div className="button_flex ">
				<Link className="outline_pink_btn" to={`/${params.store}/login`}>
					戻る
				</Link>
				<button
					type="submit"
					className="normal_btn"
					onClick={handleSubmit(onSubmit)}
				>
					送信する
				</button>
			</div>
		</>
	);
};
